import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { CardDeck } from 'reactstrap'
import CardSmall from '../cardSmall/CardSmall'

const FeaturedDeck = (props) => {
  const { posts, limit = 6, title = '', cardTag = 'h2' } = props
  const cards = posts
  .filter(({node}) => node.frontmatter.featured)
  .filter((i, index) => (index < limit))
  .map(({ node }) => {
    return (
      <CardSmall
        key={node.fields.slug}
        title={get(node, 'frontmatter.title') || node.fields.slug}
        slug={node.fields.slug}
        image={node.frontmatter.coverImage}
        excerpt={node.excerpt}
        cardTag={cardTag}/>
    )
  })

  return (
    <div>
      <div className="text-center">
        {cards.length && title ? <h2 className="widget-title mb-4">{title}</h2> : ''}
      </div>
      <CardDeck>
        {cards}
      </CardDeck>
    </div>
  )
}

export default FeaturedDeck

export const FeaturedDeckFragment = graphql`
  fragment FeaturedDeckFragment on MarkdownRemark {
    excerpt(pruneLength: 180)
    fields {
      slug
    }
    frontmatter {
      title
      category
      featured
      coverImage {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
