import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout/Layout'
import SEO from '../components/seo/SEO'
import FeaturedDeck from '../components/featuredDeck/FeaturedDeck'
import { Row, Col, Button } from 'reactstrap'

class HomePage extends React.Component {
  render() {
    const siteTitle = 'Weighted Blanket Guides'
    const siteDescription = 'Weighted Blanket Guides'
    const posts = get(this, 'props.data.allMarkdownRemark.edges')
    const siteConfig = get(this, 'props.data.site.siteMetadata')
    const seoConfig = {
      ...siteConfig,
      url: get(this, 'props.location.href', `https://www.weightedblanketguides.com/`),
      title: siteTitle,
      description: siteDescription
    }

    return (
      <Layout location={this.props.location} posts={posts} layout="fullwidth">
        <SEO postData={{}} postImage={''} isBlogPost={false} config={seoConfig}/>
        <Row>
          <Col xs="12" lg="6">
            <h1 className="text-center pb-3">Weighted Blanket Guides</h1>
            <p>How can this site help you find the right weighted blanket for you?</p>
            <Row className="pt-3">
              <Col xs="3"><i className="fas fa-microscope fa-4x"></i></Col>
              <Col xs="9"><p>Dives deep into the <strong>reasons and research behind</strong> the claimed health benefits. Tailored to general or more specific needs.</p></Col>
            </Row>
            <Row className="pt-3">
              <Col xs="3"><i className="fas fa-shopping-cart fa-4x"></i></Col>
              <Col xs="9"><p>Offers a detailed <strong>Buying Guide</strong> for those who need some guidance.</p></Col>
            </Row>
            <Row className="pt-4">
              <Col xs="3"><i className="fas fa-search-dollar fa-4x pl-2"></i></Col>
              <Col xs="9"><p><strong>Reviews</strong> the most popular, top quality as well as budget weighted blankets on the market today.</p></Col>
            </Row>
            <Row className="py-5">
              <Col xs="12" className="text-center">
                <Link to="/benefits/"><Button color="warning" className="colorAmazonYellow" size="lg">GET STARTED</Button></Link>
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="6">
            <Img fluid={{...this.props.data.file.childImageSharp.fluid}} alt="Weighted Blanket Reviews Home"/>
          </Col>
        </Row>
        <Row className="pt-5">
          <Col xs="12" md={{ size: 8, offset: 2 }}>
            <FeaturedDeck posts={posts} limit='3' cardTag='h3' title='Popular Content on Weighted Blanket Guides'/>
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default HomePage

export const HomePageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___dateModified], order: DESC },
      filter: { fileAbsolutePath: {regex : "\/posts/"}, frontmatter: { slug: { eq: "guide-how-to-choose" } } }
    ) {
        edges {
          node {
            ...FeaturedDeckFragment
          }
        }
    }
    file(relativePath: {eq: "weighted-blankets-home-650.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
