import React from 'react'
import { Link } from 'gatsby'
import './cardSmall.scss'
import { Card, CardImg, CardBody } from 'reactstrap'
import Img from 'gatsby-image'

const CardSmall = (props) => {
  const image = props.image ? props.image.childImageSharp.fluid : null
  const CardTag = props.cardTag ? props.cardTag : 'h2'

  return (
    <Card key={props.slug} className="cardSmall">
      <Link style={{ boxShadow: 'none' }} to={props.slug}>
        {image ?
          <Img fluid={{...props.image.childImageSharp.fluid, aspectRatio: 57/31}} alt={props.title} />
          :
          <CardImg top src="https://via.placeholder.com/348x215" alt="Alt"/>
        }
        <CardBody className="cardSmall__body">
          <CardTag className="cardSmall__title">{props.title}</CardTag>
          <p dangerouslySetInnerHTML={{ __html: props.excerpt }} />
        </CardBody>
      </Link>
    </Card>
  )
}

export default CardSmall
